import { GET_FACE_VERIFICATION } from "../types";

const initialState = {
  faceVerification: [],
};

export const FaceVerificationLogReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_FACE_VERIFICATION: {
      let faceVerification = actions.payload.all;
      return {
        ...state,
        faceVerification,
      };
    }
    default:
      return state;
  }
};
