const initialState = {
  test: "",
};

export const TestReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case "hello": {
      console.log("reducer function");
      return {
        ...state,
        test: actions.payload,
      };
    }
    default:
      return state;
  }
};
