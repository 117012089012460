import { GET_TICKET_PLANS } from "../types";

const initialState = {
  allTicketPlans: [],
};

export const TicketPlansReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TICKET_PLANS: {
      return {
        ...state,
        allTicketPlans: actions.payload.all,
      };
    }
    default:
      return state;
  }
};
