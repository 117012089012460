import { GET_EVENTS, GET_EVENT, EMPTY_RESULT, GET_ORG_EVENTS, SET_EVENT_SUCCESS, GET_EVENT_PLAN_TRANSACTIONS, GET_PENDING_EVENTS } from "../types";

const initialState = {
  events: [],
  event: null,
  orgEvents: [],
  eventCreationSuccessInfo: null,
  eventPlanTransactions: [],
  processedEvents: [],
  allEventsInfo: {}
};

export const EventReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_EVENTS: {
      const fetched_events = actions.payload.all;
      const newEvents = fetched_events.map(item => ({value: item.event_id, label: item.event_name}))
    
      return {
        ...state,
        events: actions.payload.all,
        processedEvents: newEvents
      };
    }
    case GET_EVENT: {
      return {
        ...state,
        event: actions.payload,
      };
    }
    case EMPTY_RESULT: {
      return {
        ...state,
        events: [],
      };
    }
    case GET_ORG_EVENTS: {
      const fetched_events = actions.payload.all;
      const newEvents = fetched_events.map(item => ({value: item.event_id, label: item.event_name}))
    
      return {
        ...state,
        orgEvents: actions.payload.all,
        processedEvents: newEvents
      };
    }
    case SET_EVENT_SUCCESS: {
     
      return {
        ...state,
        eventCreationSuccessInfo: actions.payload
      }
    }
    case GET_EVENT_PLAN_TRANSACTIONS: {
      return {
        ...state,
        eventPlanTransactions: actions.payload.all
      }
    }
    case GET_PENDING_EVENTS: {
      return {
        ...state,
        allEventsInfo: actions.payload
      }
    }
    default:
      return state;
  }
};
