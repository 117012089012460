import { combineReducers } from "redux";
import reducer from "../reducer";
import { EventReducer } from "./EventReducer";
import { OrganizerReducer } from "./OrganizerReducer";
import { TestReducer } from "./TestReducer";
import { AccessCardsReducer } from "./AccessCardsReducer";
import { AdminReducer } from "./AdminReducer";
import { TicketReducer } from "./TicketReducer";
import { TimelineReducer } from "./TimelineReducer";
import { InvitationsReducer } from "./InvitationsReducer";
import { ActivityLogReducer } from "./ActivityLogReducer";
import { PaymentsReducer } from "./PaymentsReducer";
import { TurnstileReducer } from "./TurnstileReducer";
import { UsersReducer } from "./UsersReducer";
import { FaceVerificationLogReducer } from "./FaceVerificationReducer";
import { TicketUserReducer } from "./TicketUserReducer";
import { TicketPlansReducer } from "./TicketPlansReducer";
import { TurnstileCostReducer } from "./TurnstileCostReducer";
import { TimelineBundlesReducer } from "./TimelineBundlesReducer";
import { ReportReducer } from "./ReportReducer";

const reducers = combineReducers({
  LayoutReducer: reducer,
  Organizer: OrganizerReducer,
  Events: EventReducer,
  AccessCards: AccessCardsReducer,
  TestReducer: TestReducer,
  Admin: AdminReducer,
  Tickets: TicketReducer,
  Timeline: TimelineReducer,
  Invitations: InvitationsReducer,
  ActivityLog: ActivityLogReducer,
  Payments: PaymentsReducer,
  Turnstile: TurnstileReducer,
  Users: UsersReducer,
  FaceVerification: FaceVerificationLogReducer,
  TicketUser: TicketUserReducer,
  TicketPlans: TicketPlansReducer,
  TurnstileCosts: TurnstileCostReducer,
  TimelineBundles: TimelineBundlesReducer,
  Reports: ReportReducer
});

export default reducers;
