import { GET_ADMIN, GET_DASHBOARD } from "../types";

const initialState = {
  admins: [],
  dashboard: null,
};

export const AdminReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_ADMIN: {
      return {
        ...state,
        admins: actions.payload.all,
      };
    }
    case GET_DASHBOARD: {
      return {
        ...state,
        dashboard: actions.payload,
      };
    }
    default:
      return state;
  }
};
