import { GET_TICKET_USER } from "../types";

const initialState = {
  ticketUsers: [],
};

export const TicketUserReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TICKET_USER: {
      return {
        ...state,
        ticketUsers: actions.payload.all,
      };
    }
    default:
      return state;
  }
};
