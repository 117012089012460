import {
  GET_ADMIN_ACTIVITY_LOG,
  GET_ORGANISERS_ACTIVITY_LOG,
  GET_SELF_ACTIVITY_LOG,
} from "../types";

const initialState = {
  selfActivitylog: [],
  adminActivityLog: [],
  organisersActivityLog: [],
};

export const ActivityLogReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_SELF_ACTIVITY_LOG: {
      return {
        ...state,
        selfActivitylog: actions.payload.all,
      };
    }
    case GET_ADMIN_ACTIVITY_LOG: {
      return {
        ...state,
        adminActivityLog: actions.payload.all,
      };
    }
    case GET_ORGANISERS_ACTIVITY_LOG: {
      return {
        ...state,
        organisersActivityLog: actions.payload.all,
      };
    }
    default:
      return state;
  }
};
