import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-notifications/lib/notifications.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datetime/css/react-datetime.css";
import "./assets/override.css";
import "./assets/other.css";
import 'react-intl-tel-input/dist/main.css';
import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";
import config from "./config";
import store from "./store";

import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationContainer } from "react-notifications";

const queryClient = new QueryClient();

// const store = createStore(reducer);

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={config.basename}>
        <App />
        <NotificationContainer />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
