import { GET_TURNSTILE } from "../types";

const initialState = {
  turnstile: [],
};

export const TurnstileReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TURNSTILE: {
      let turnstile = actions.payload.all;
      return {
        ...state,
        turnstile,
      };
    }
    default:
      return state;
  }
};
