import { GET_ALL_TICKETS, GET_TICKETS } from "../types";

const initialState = {
  tickets: [],
  allTickets: [],
};

export const TicketReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TICKETS: {
      return {
        ...state,
        tickets: actions.payload.all,
      };
    }
    case GET_ALL_TICKETS: {
      return {
        ...state,
        allTickets: actions.payload.all,
      };
    }
    default:
      return state;
  }
};
