import { GET_ORGANIZERS, GET_ORGANIZER_STAFF } from "../types";

const initialState = {
  organizers: [],
  organizerStaff: [],
  processedOrgs: []
};

export const OrganizerReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_ORGANIZERS: {
      let orgs = actions.payload.all;
      const newOrgs = orgs.map(item => ({value: item.organiser_id, label: item.organiser_name}))
    
      return {
        ...state,
        organizers: orgs,
        processedOrgs: newOrgs
      };
    }
    case GET_ORGANIZER_STAFF: {
      return {
        ...state,
        organizerStaff: [actions.payload],
      };
    }
    default:
      return state;
  }
};
