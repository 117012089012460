import { GET_TIMELY_REPORT, GET_ORGANISER_REPORT } from "../types";

const initialState = {
  organiser_reports: null,
  summary_reports: null,
};

export const ReportReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_ORGANISER_REPORT: {
      return {
        ...state,
        organiser_reports: actions.payload,
      };
    }
    case GET_TIMELY_REPORT: {
      return {
        ...state,
        summary_reports: actions.payload,
      };
    }
    default:
      return state;
  }
};
