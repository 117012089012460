import { GET_TIMELINE_BUNDLES, SET_SELECTED_TIMELINE_BUNDLE } from "../types";

const initialState = {
  allTimelineBundles: [],
  selectedTimelineBundleID: ""
};

export const TimelineBundlesReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TIMELINE_BUNDLES: {
      return {
        ...state,
        allTimelineBundles: actions.payload.all,
      };
    }
    case SET_SELECTED_TIMELINE_BUNDLE: {
     
        return {
            ...state,
            selectedTimelineBundleID: actions.payload
        }
    }
    default:
      return state;
  }
};
