import { GET_USERS, SET_SELECTED_USER_ID, USERS_SEARCH } from "../types";

const initialState = {
  users: [],
  processedUsers: [],
  users_search_list: [],
  selected_user_id: ""
};

export const UsersReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_USERS: {
      let users = actions.payload.all;
      const newUsers = users.map(item => ({value: item.user_id, label: item.user_fullname}))
    
      return {
        ...state,
        users,
        processedUsers: newUsers
      };
    }
    case USERS_SEARCH: {
      return {
        ...state,
        users_search_list: actions.payload
      }
    }
    case SET_SELECTED_USER_ID: {
      return {
        ...state,
        selected_user_id: actions.payload
      }
    }
    default:
      return state;
  }
};
