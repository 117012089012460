import { GET_ACCESS_CARDS } from "../types";

const initialState = {
  accessCards: [],
};

export const AccessCardsReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_ACCESS_CARDS: {
      return {
        ...state,
        accessCards: actions.payload.all,
      };
    }
    default:
      return state;
  }
};
