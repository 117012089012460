import { GET_ORGANIZERS, GET_PAYMENTS } from "../types";

const initialState = {
  payments: [],
};

export const PaymentsReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_PAYMENTS: {
      let payments = actions.payload.all;
      return {
        ...state,
        payments,
      };
    }
    default:
      return state;
  }
};
