import { GET_TURNSTILE_COST, SET_SELECTED_TIMELINE_BUNDLE } from "../types";

const initialState = {
  allTurnstileCost: [],
 
};

export const TurnstileCostReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TURNSTILE_COST: {
      return {
        ...state,
        allTurnstileCost: actions.payload.all,
      };
    }
   
    default:
      return state;
  }
};
