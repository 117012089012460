export const GET_ORGANIZERS = "GET_ORGANIZERS";
export const GET_ORGANIZER_STAFF = "GET_ORGANIZER_STAFF";
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT = "GET_EVENT";
export const GET_ORG_EVENTS = "GET_ORG_EVENTS";
export const SET_EVENT_SUCCESS ="EVENT_CREATED_SUCCESSFULLY"
export const GET_PENDING_EVENTS = "GET_PENDING_EVENTS"

export const GET_ACCESS_CARDS = "GET_ACCESS_CARDS";
export const GET_ADMIN = "GET_ADMIN";
export const GET_DASHBOARD = "GET_DASHBOARD";

export const GET_TICKETS = "GET_TICKETS";
export const GET_ALL_TICKETS = "GET_ALL_TICKETS";

export const GET_TIMELINE = "GET_TIMELINE";

export const GET_INVITATIONS = "GET_INVITATIONS";
export const GET_SELF_ACTIVITY_LOG = "GET_SELF_ACTIVITY_LOG";
export const GET_ADMIN_ACTIVITY_LOG = "GET_ADMIN_ACTIVITY_LOG";
export const GET_ORGANISERS_ACTIVITY_LOG = "GET_ORGANISERS_ACTIVITY_LOG";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_TURNSTILE = "GET_TURNSTILE";
export const GET_USERS = "GET_USERS";
export const USERS_SEARCH = "USERS_SEARCH"
export const GET_FACE_VERIFICATION = "GET_FACE_VERIFICATION";

export const EMPTY_RESULT = "EMPTY_RESULT";

export const GET_TICKET_USER = "GET_TICKET_USER";

// Ticket Plans

export const GET_TICKET_PLANS = "GET_TICKET_PLANS";

export const GET_TURNSTILE_COST = "GET_TURNSTILE_COST";

export const GET_TIMELINE_BUNDLES = "GET_TIMELINE_BUNDLES";

export const SET_SELECTED_TIMELINE_BUNDLE = "SET_SELECTED_TIMELINE_BUNDLE"
export const SET_SELECTED_USER_ID = "SET_SELECTED_USER_ID"


export const GET_EVENT_PLAN_TRANSACTIONS = "GET_EVENT_PLAN_TRANSACTIONS"

export const GET_ORGANISER_REPORT = "GET_ORGANISER_REPORT"
export const GET_TIMELY_REPORT = "GET_TIMELY_REPORT"