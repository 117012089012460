import { GET_TIMELINE } from "../types";

const initialState = {
  timeline: [],
};

export const TimelineReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_TIMELINE: {
      return {
        ...state,
        timeline: actions.payload.all,
      };
    }
    default:
      return state;
  }
};
